<script setup>
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { useIsMobile } from "@/utils/checkMobile";
import { applyStyleToVariable } from "@/utils/editorStyling";
import { formatIsoDate } from "@/utils/formatDate";

const store = useStore();
const { t } = useI18n();
const isHidden = ref(true);
const { isAiAssistantOpen, documentId } = defineProps(["isAiAssistantOpen", "documentId"]);
const emit = defineEmits(["save", "closeEditor"]);

const errorMessage = ref("");
const description = computed(() => store.state.editor.editableVariable.description ?? "");
const label = computed(() => store.state.editor.editableVariable.name ?? "");
const type = computed(() => store.state.editor.editableVariable.type ?? "text");
const variableId = computed(() => store.state.editor.editableVariable.id ?? null);
const options = computed(() => store.state.editor.editableVariable.options ?? []);
const max_options = computed(() => store.state.editor.editableVariable.max_options);
const list_type = computed(() => store.state.editor.editableVariable.list_type);

const value = computed({
  get() {
    errorMessage.value = "";
    if (type.value === "date") {
      const date = store.state.editor.editableVariable.value;
      const year = date?.split("-")[0] || "";
      const isIsoFormat = year?.length === 4;

      if (isIsoFormat || !date) {
        return date;
      } else {
        return date?.split("-").reverse().join("-");
      }
    } else {
      return store.state.editor.editableVariable.value;
    }
  },
  set(value) {
    const newVariable = {
      name: label.value,
      value: value,
      answer: value,
      description: description.value,
      type: type.value,
      id: variableId.value,
    };

    if (type.value === "select") {
      const selectedOptions = options.value.filter((option) => value.includes(option));

      newVariable.value = [...selectedOptions];
      newVariable.answer = [...selectedOptions];
      newVariable.options = options.value;
      newVariable.max_options = max_options.value;
      newVariable.list_type = list_type.value;
    }

    store.commit("editor/updateEditableVariable", newVariable);
    errorMessage.value = "";
  },
});

const isMobile = useIsMobile();

const handleCloseEditor = () => {
  if (!isHidden.value) {
    store.commit("editor/updateEditableVariable", {
      name: "",
      value: "",
      description: "",
      type: "text",
      id: null,
      answer: "",
    });
    applyStyleToVariable(null);
    isHidden.value = true;
    emit("closeEditor");
  } else {
    isHidden.value = false;
  }
};

const validation = () => {
  if (type.value === "select") {
    if (!value.value?.length) {
      errorMessage.value = t("errors.wizard.min_options");
      return true;
    }

    if (max_options.value < value.value.length) {
      errorMessage.value = `${t("errors.wizard.max_options1")} ${max_options.value} ${t("errors.wizard.max_options2")}`;
      return true;
    }
  }

  if (!value.value) {
    errorMessage.value = t("errors.editor.variable_value_required");
    return true;
  }

  return false;
};

const saveValue = async () => {
  if (validation()) return;

  let newVariableValue = value.value;

  if (type.value === "date") {
    newVariableValue = formatIsoDate(newVariableValue);
  }

  if (type.value === "select") {
    newVariableValue = newVariableValue.toString();
  }

  await axios.patch(`api/documents/${documentId}/meta/variables/answer`, {
    identifier: variableId.value,
    answer: newVariableValue,
  });

  store.commit("editor/updateVariable", {
    id: variableId.value,
    value: newVariableValue,
    answer: newVariableValue,
  });

  handleCloseEditor();
};

watch(
  () => variableId.value,
  (newValue) => {
    if (newValue) {
      isHidden.value = false;
    }
  },
);
</script>

<template>
  <div v-if="store.state.editor.editableVariable" class="variable-inputs-container">
    <!-- Mobile View -->
    <template v-if="isMobile">
      <h3 v-if="description !== ''">{{ description }}</h3>
      <v-card v-if="description !== ''">
        <span class="input-label"> {{ label }} </span>
        <v-text-field
          v-if="type !== 'select'"
          variant="outlined"
          v-model="value"
          :placeholder="label"
          :type="type"
          :error-messages="errorMessage"
        ></v-text-field>

        <div class="options-fields" v-if="type === 'select'">
          <v-checkbox
            v-for="(option, index) in options"
            v-model="value"
            :label="option"
            :value="option"
            hide-details
            density="compact"
          ></v-checkbox>
        </div>
        <p v-if="type === 'select' && errorMessage" class="max-options-label">
          {{ errorMessage }}
        </p>
      </v-card>
      <v-btn v-if="label" @click="saveValue" class="mobile-save-button" variant="flat">
        {{ $t("common.save") }}
      </v-btn>
    </template>

    <!-- Desktop View -->
    <template v-else>
      <h3 v-if="description !== ''">{{ description }}</h3>
      <v-card v-if="description !== ''">
        <span class="input-label"> {{ label }} </span>
        <v-text-field
          v-if="type !== 'select'"
          variant="outlined"
          v-model="value"
          :placeholder="label"
          :type="type"
          :error-messages="errorMessage"
        ></v-text-field>

        <div class="options-fields" v-if="type === 'select'">
          <v-checkbox
            v-for="(option, index) in options"
            v-model="value"
            :label="option"
            :value="option"
            hide-details
            density="compact"
          ></v-checkbox>
        </div>
        <p v-if="type === 'select' && errorMessage" class="max-options-label">
          {{ errorMessage }}
        </p>
      </v-card>
      <div class="wizard-buttons">
        <v-btn v-if="label" @click="saveValue" class="forward-button" variant="flat">
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.max-options-label {
  font-size: 0.8rem;
  color: var(--eerie-black);
  margin-top: 10px;
  color: rgb(176, 0, 32);
}
:deep(.v-card-item) {
  padding: 0;
  margin-top: 20px;
}

:deep(.v-card-text) {
  padding: 0;
  margin-top: 5px;
}

.v-card {
  border: none;
}

.variable-inputs-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-height: 50%;
  padding: 10px;
  transition: 0.3s ease-in-out;
  overflow: hidden;

  .input-label {
    font-size: 0.875rem;
    color: var(--eerie-black);
  }

  :is(h3) {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .variable-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    border: #111111 1px solid;
    height: 80%;
    padding: 20px;

    .single-input {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .single-input__label {
        font-weight: 400;
        padding-top: 8px;
        text-align: left;
        text-transform: capitalize;
      }

      input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #111111;
        width: 60%;
      }
    }
  }
}
.wizard-buttons {
  display: flex;
  justify-content: end;
  gap: 16px;

  .forward-button {
    background-color: var(--blue-bonnet) !important;
    color: white !important;
  }

  .forward-button,
  .back-button {
    text-decoration: none;
    color: #858788;
    font-size: 0.688rem;

    :is(img) {
      width: 5px;
    }

    &:hover {
      color: black;
    }
  }

  .forward-button {
    :is(img) {
      transform: rotate(180deg);
    }
  }
}

.mobile-save-button {
  color: white;
  background: var(--cambridge-blue);
  margin: 25px 50px 50px;
  font-size: 1.125rem;
}
</style>
