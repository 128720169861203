const setDevice = () => {
  switch (true) {
    case window.innerWidth <= 768:
      return "mobile";
    case window.innerWidth <= 1280:
      return "tablet";
    default:
      return "computer";
  }
};

const useUserGuiding = (user) => {
  const userId = user?.id || crypto.randomUUID();

  if (window.userGuiding) {
    window.userGuiding.identify(userId, {
      id: userId,
      email: user?.email,
      name: user?.name,
      device_custom: setDevice(),
    });
  } else {
    setTimeout(() => {
      useUserGuiding(user);
    }, 1000);
  }
};

export default useUserGuiding;
