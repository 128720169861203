<script setup>
import Loader from "@/components/Loader.vue";

import { useStore } from "vuex";

import useUserGuiding from "@/utils/userGuiding";

const store = useStore();
const isLoading = computed(() => store.state.shared.isLoading);

onMounted(() => {
  const user = store.state.auth.user;

  useUserGuiding(user);
});
</script>
<template>
  <Loader v-if="isLoading" />
  <router-view />
</template>
