import store from "@/store/store";
import axios from "axios";

import { applyStyleToParagraph } from "@/utils/editorStyling";
import pause from "@/utils/pause";
import scrollToElement from "@/utils/scrollToElement";
import parseXMLtoJSON from "@/utils/xmlParser/parseXMLToJSON";

export default async function handleChangeDocument(paragraph_id, newDocument) {
  console.log("handleChangeDocument", paragraph_id, newDocument);
  const parsedDocument = parseXMLtoJSON(newDocument);
  store.commit("editor/setRestoreVersion", parsedDocument);
  // applyStyleToParagraph(paragraph_id, "info");

  // const paragraphAsNode = document.querySelector(`[data-id*="${paragraph_id}"]`);
  // pause(1000);

  // scrollToElement(paragraphAsNode);
}

const mockNewDocument = "";
