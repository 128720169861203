import parseXmlToNodes from "./parseXmlToNodes";

function removeNbSp(str) {
  try {
    return str.replace(/&nbsp;/g, " ");
  } catch (error) {
    console.error("Error removing non-breaking spaces", error);
    console.log("str:", str);
    return "";
  }
}

const isXML = async (document) => {
  if (!document) return false;
  if (typeof document === "object") {
    return false;
  }

  try {
    const content = await JSON.parse(document);
    return false;
  } catch (error) {
    return true;
  }
};

const parseXMLToJSON = (document) => {
  if (!isXML(document)) return document;

  const contentData = document ? removeNbSp(document) : "";
  const itsVariable = /^\s*[\[{]/.test(contentData);
  const itsHTMLTag = /^\s*</.test(contentData);

  if (itsVariable) {
    try {
      return JSON.parse(contentData);
    } catch (error) {
      console.error("Error parsing JSON content", error);
    }
  } else if (itsHTMLTag) {
    try {
      return parseXmlToNodes(contentData);
    } catch (error) {
      console.error("Error parsing XML content", error);
    }
  } else {
    console.error("Unknown content format");
  }
};

export default parseXMLToJSON;
