<script setup>
import Page from "@/components/Page.vue";
import SubscriptionOrSinglePaymentRequiredDialog from "@/components/dialogs/SubscriptionOrSinglePaymentRequiredDialog.vue";
import LegalAssistant from "@/components/mobile/LegalAssistant.vue";

import router from "@/router";
import store from "@/store/store";
import algoliasearch from "algoliasearch/lite";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

const appId = import.meta.env.VITE_ALGOLIA_APP_ID;
const searchKey = import.meta.env.VITE_ALGOLIA_SEARCH_KEY;

const searchClient = algoliasearch(appId, searchKey);
const index = searchClient.initIndex("templates");

const route = useRoute();
const category = ref();
const templates = ref([]);
const searchQuery = ref("");

const isPaymentDialogOpen = ref(false);
const selectedTemplateId = ref();

const fetchTemplates = async () => {
  const { hits } = await index.search(searchQuery.value, {
    filters: `category_id:${route.params.categoryId}`,
  });
  templates.value = hits;
};

const fetchCategory = async () => {
  const response = await axios.get(`/api/template-categories/${route.params.categoryId}`);
  category.value = response.data.data;
};

const handleClearSearchbox = () => {
  searchQuery.value = "";
};

const handleTemplate = async (templateId) => {
  try {
    const response = await axios.post("api/documents", {
      template_id: templateId,
    });

    store.commit("editor/updateDocumentId", response.data.data.id);
    store.commit("chat/updateSessionId", response.data.data.id);

    await router.push({
      name: "new-document-creator",
      params: { uuid: response.data.data.id },
    });
  } catch (error) {
    if (error.response.status === 402) {
      selectedTemplateId.value = templateId;
      isPaymentDialogOpen.value = true;
    }
  }
};
onMounted(async () => {
  await fetchTemplates();
  await fetchCategory();
});
watch(searchQuery, async () => {
  await fetchTemplates();
});
</script>

<template>
  <Page :title="category?.name" :with-back-button="true" :with-spacing="false">
    <div class="search-container">
      <v-text-field
        v-model="searchQuery"
        id="searchbox"
        variant="outlined"
        density="compact"
        :placeholder="$t('common.search')"
      >
        <template v-slot:append-inner>
          <img v-if="!searchQuery.length" src="/src/assets/icons/search.svg" alt="Search" />
          <img
            v-else
            class="clear-searchbox-btn"
            src="/src/assets/icons/x.svg"
            alt="Clear search"
            @click="handleClearSearchbox"
          />
        </template>
      </v-text-field>
    </div>
    <div class="templates-list">
      <div
        v-for="(template, index) in templates"
        v-bind:key="template.id"
        class="template-container"
      >
        <div class="template-name" @click="handleTemplate(template.id)">
          {{ template.name }}
        </div>
        <div>{{ template.description }}</div>
        <hr v-if="index < templates.length - 1" />
      </div>
    </div>
    <template #footer>
      <div class="assistant">
        <LegalAssistant route="/general-chat" />
      </div>
    </template>
    <SubscriptionOrSinglePaymentRequiredDialog
      v-if="selectedTemplateId"
      :template-id="selectedTemplateId"
      :is-dialog-open="isPaymentDialogOpen"
      @close-dialog="isPaymentDialogOpen = false"
    />
  </Page>
</template>

<style scoped lang="scss">
.assistant {
  position: fixed;
  bottom: 73px;
  left: 0;
  width: 100%;
}

.search-container {
  height: 42px;
  margin-bottom: 20px;
}
.templates-list {
  margin-bottom: 100px;
}
.template-container {
  padding: 10px;
  color: var(--eerie-black);
  font-size: 0.875rem;
  cursor: pointer;

  .template-name {
    text-decoration: underline;
    font-weight: 600;
    font-size: 1rem;
  }

  hr {
    margin-top: 20px;
  }
}
</style>
