<script setup>
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

const { plan, plansBenefits, userData } = defineProps(["plan", "plansBenefits", "userData"]);
const emit = defineEmits(["closeModal"]);

const { t, locale } = useI18n();
const router = useRouter();

const createSubscription = async (plan, provider) => {
  await axios
    .post("/api/user/subscription/create", {
      plan: plan,
      provider: provider,
    })
    .then((res) => {
      emit("closeModal");

      if (userData?.is_temporary) {
        router.push("/register");
      } else {
        window.location.replace(res.data.data.checkout_url);
      }
    })
    .catch((error) => {
      useToast().error(error.response.data.message);
    });
};
</script>
<template>
  <VCard
    :class="plan.is_default ? 'default_plan' : 'plan'"
    style="margin-bottom: 110px"
    :key="plan.id"
  >
    <VCardItem>
      <div class="plan-container">
        <img class="plan-marker-icon" src="/src/assets/icons/plan-marker.svg" alt="Plan Marker" />
        <span class="plan-title"> {{ plan.name }} </span>
        <span class="plan-price-container">
          <span class="plan-price"> {{ plan.unit_price }} {{ plan.currency_symbol }} </span>
          <span class="plan-price-suffix"> / {{ t("billing.per_month") }} </span>
        </span>
        <span class="plan-price-container">
          <span class="plan-price-subtitle"> {{ plan.price }} {{ plan.currency_symbol }} </span>
          <span class="plan-price-subtitle-suffix"> / {{ t("billing.total") }} </span>
        </span>

        <v-menu>
          <template v-slot:activator="{ props }">
            <VBtn class="plan-button" variant="flat" v-bind="props">
              {{ t("common.select") }}
            </VBtn>
          </template>

          <v-list>
            <v-list-item
              :title="$t('billing.traditional_payments')"
              key="stripe"
              @click="() => createSubscription(plan.id, 'stripe')"
            />
            <v-list-item
              :title="$t('billing.crypto_payments')"
              key="vaiot"
              @click="() => createSubscription(plan.id, 'vaiot')"
            />
          </v-list>
        </v-menu>

        <div class="features-list">
          <span class="feature" v-for="feature in plansBenefits">
            <img src="/src/assets/icons/tick.png" />
            <span> {{ feature }} </span>
          </span>
        </div>
      </div>
    </VCardItem>

    <template v-if="plan.is_default">
      <img
        v-if="locale === 'en'"
        width="150"
        height="120"
        style="position: absolute; right: 0; bottom: 0"
        src="/src/assets/images/plan-recommended.svg"
        alt="Default Plan"
      />

      <img
        v-if="locale === 'pl'"
        width="150"
        height="120"
        style="position: absolute; right: 0; bottom: 0"
        src="/src/assets/images/plan-recommended-pl.svg"
        alt="Default Plan"
      />
    </template>
  </VCard>
</template>
<style scoped lang="scss">
.plan,
.default_plan {
  width: 320px;
  min-height: 365px;
  max-height: 365px;

  @media (max-width: 768px) {
    width: 100% !important;
    padding: 30px;
    padding-top: 0 !important;
    box-shadow: none !important;
    margin-bottom: 15px !important;
  }
}

.plan-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .plan-marker-icon {
    position: absolute;
    top: 0;
    left: 20px;
    width: 30px;
    height: 30px;
    z-index: 2;
  }

  .plan-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 1.25rem;
    color: var(--eerie-black);
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  .plan-price-container {
    display: flex;
    align-items: flex-start;
    gap: 6px;
  }

  .plan-price-subtitle {
    font-size: 0.875rem;
    color: var(--cambridge-blue);
    font-weight: bold;
    margin-left: 25px;
  }

  .plan-price {
    font-size: 1.5rem;
    color: var(--eerie-black);
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 25px;
  }

  .plan-price-subtitle-suffix {
    font-size: 0.5rem;
    color: var(--cambridge-blue);
  }

  .plan-price-suffix {
    font-size: 0.688rem;
    color: var(--dark-grey);
    margin-top: 5px;
  }

  .plan-button {
    margin-top: 20px;
    background-color: var(--cambridge-blue);
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 5px;
    margin-right: 25px;
    margin-left: 25px;
  }
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  padding-left: 22px;

  @media (max-width: 768px) {
    padding-left: 15px;
    margin: 20px 0;
  }
}

.feature {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.75rem;
  color: var(--eerie-black);
}

.default_plan {
  min-height: 408px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card-item {
  padding: 0;
}
</style>
