import store from "@/store/store";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const useDocusign = () => {
  const toast = useToast();
  const { t } = useI18n();

  const setLatestOpenedDocumentId = (documentId) => {
    localStorage.setItem("latestOpenedDocumentId", documentId);
  };

  const redirectToDocusign = async () => {
    try {
      const response = await axios.get("/api/docusign/auth-url");

      if (response.status === 200) {
        setTimeout(() => {
          window.location.href = response.data.url;
        });
      }
    } catch (error) {
      console.error(t("docusign.redirect_error"), error);
    }
  };

  const uploadDocumentToDocusign = async (documentId) => {
    const hasConnectedDocusign = store.state.auth.user.hasConnectedDocusign;

    if (!hasConnectedDocusign) {
      setLatestOpenedDocumentId(documentId);
      return await redirectToDocusign(documentId);
    }

    try {
      const response = await axios.post(`/api/docusign/create-envelope`, {
        document: documentId,
      });

      toast.success(t("docusign.upload_success"));
      toast.info(t("docusign.warning_blocking_windows"));
      setTimeout(() => {
        window.open(response.data.url, "_blank").focus();
      });
    } catch (error) {
      toast.error(t("docusign.upload_error"));
      console.error(t("docusign.upload_error"), error);
    }
  };

  return { uploadDocumentToDocusign, redirectToDocusign };
};

export default useDocusign;
