<script setup>
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const props = defineProps(["isDialogOpen", "document"]);
const emit = defineEmits(["closeDialog"]);

const toast = useToast();
const { t } = useI18n();

const sendEmailWithDocumentForm = ref();
const recipientEmail = ref(null);

const sendEmailWithDocument = async () => {
  if (await sendEmailWithDocumentForm.value.validate().valid) {
    return;
  }

  try {
    await axios.post(`/api/documents/${props.document.id}/send-email`, {
      recipient_email: recipientEmail.value,
    });

    toast.success(t("common.send"));
  } catch (error) {
    toast.error(t("notifications.default_error"));
  }

  emit("closeDialog");
  recipientEmail.value = null;
};

const emailRules = [
  (v) => !!v || t("validation.required"),
  (v) => /.+@.+\..+/.test(v) || t("validation.email"),
];
</script>

<template>
  <v-dialog v-model="props.isDialogOpen" max-width="500">
    <v-form @submit.prevent="sendEmailWithDocument" ref="sendEmailWithDocumentForm">
      <v-card :title="t('creator.share_email')">
        <v-card-text>
          {{ t("creator.ask_for_email") }}

          <v-text-field
            v-model="recipientEmail"
            :rules="emailRules"
            class="mt-3"
            label="Email"
            outlined
            dense
            clearable
            required
            variant="underlined"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn text="Cancel" variant="tonal" @click="emit('closeDialog')"></v-btn>

          <v-spacer></v-spacer>

          <v-btn type="submit" color="primary" variant="flat"> {{ t("contact_form.send") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
