import { createI18n } from "vue-i18n";

import { en } from "../translations/en.js";
import { pl } from "../translations/pl.js";

const localeFromENV = import.meta.env.VITE_APP_LOCALE;

export const availableLanguages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "pl",
    name: "Polski",
  },
];

// const currentLanguage = localStorage.lang || getBrowserLanguage();
const currentLanguage = localeFromENV;

function getBrowserLanguage() {
  let browserLanguage = navigator.language || navigator.language;
  browserLanguage = browserLanguage.split("-")[0];

  const isLanguageUndefined = availableLanguages.some((lang) => lang.code === browserLanguage);

  if (isLanguageUndefined) {
    browserLanguage = "en";
  }

  return browserLanguage;
}

const i18n = createI18n({
  legacy: false,
  locale: currentLanguage,
  fallbackLocale: "en",
  messages: {
    en: en,
    pl: pl,
  },
});

export default i18n;
